import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Container,
  Center,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { Link, Icon } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { Button } from '@chakra-ui/button';
import { getLoanAppDetail, dealerDecision } from '@du/services';
import { util } from '@du/business-logic';
import { Pill, Currency } from '@driverup/ui';
import { Link as RRDLink } from 'react-router-dom';
import { FiChevronLeft as BackIcon } from 'react-icons/fi';

export const ApplicationDetail = ({ match }) => {
  const loanId = match?.params?.id;
  const [activeAction, setActiveAction] = useState('');
  const queryClient = useQueryClient();
  const query = useQuery<util.GetLoanAppDetailResponse>(
    ['loan_applications_for_dealer', loanId],
    () => getLoanAppDetail({ id: loanId }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );
  const { isLoading, isError, data } = query;
  const mutation: any = useMutation((payload) => dealerDecision(payload), {
    onSettled: () => {
      // Perhaps this should only be onSuccess, for when the mutation succeeds.
      //    onSettled also runs on an error.  But, I'm not sure of specific
      //    error handling strategies for this yet.
      setActiveAction('');
      queryClient.invalidateQueries('loan_applications_for_dealer');
    },
  });

  const onAccept = () => {
    setActiveAction('a');
    const input = {
      input: {
        decision: 'APPROVE',
        loanAppId: data.id,
      },
    };
    mutation.mutate(input);
  };

  const onReject = () => {
    setActiveAction('r');
    const input = {
      input: {
        decision: 'REJECT',
        loanAppId: data.id,
      },
    };
    mutation.mutate(input);
  };

  const onUnavailable = () => {
    setActiveAction('u');
    const input = {
      input: {
        decision: 'UNAVAILABLE',
        loanAppId: data.id,
      },
    };
    mutation.mutate(input);
  };

  if (isLoading) {
    return (
      <Center h="50vh">
        <VStack>
          <Spinner size="xl" color="blue.500" />
          <Text>Getting loan application</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <Container pb={20}>
      <Link as={RRDLink} to="/dealer" mb={5}>
        <Text fontWeight="bold">
          <Icon as={BackIcon} fontSize="19px" />
          Back
        </Text>
      </Link>
      <Grid templateColumns="repeat(6, 1fr)" gap={5}>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Status
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Pill>{data.status}</Pill>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Customer name:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="left">
            {data.buyer.firstName} {data.buyer.lastName}
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Customer address:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <VStack align="start">
            <Text align="left">{data.buyer.address.addressLine1}</Text>
            <Text align="left">
              {data.buyer.address.city}, {data.buyer.address.state}
            </Text>
            <Text align="left">{data.buyer.address.zip}</Text>
          </VStack>
        </GridItem>
        <GridItem colSpan={2}>
          <Text align="center" fontWeight="bold">
            Make:
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text align="center" fontWeight="bold">
            Model:
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text align="center" fontWeight="bold">
            Year:
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text align="center"> {data.car.make}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text align="center">{data.car.model}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text align="center">{data.car.year}</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Sales price:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.dealerSalesPrice} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Cash down:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.cashDown} />
          </Text>
        </GridItem>
        {/* <GridItem colSpan={3}>
        <Text align="right" fontWeight="bold">
          Trade down:
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text>{data.tradeDown}</Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text align="right" fontWeight="bold">
          Payoff:
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text>N/A</Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text align="right" fontWeight="bold">
          Net trade:
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text>(trade down - payoff)</Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text align="right" fontWeight="bold">
          Total down:
        </Text>
      </GridItem>
      <GridItem colSpan={3}>
        <Text>(cash down + net trade)</Text>
      </GridItem> */}
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Unpaid balance:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency
              value={Number(data.dealerSalesPrice) - Number(data.cashDown)}
            />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Sales tax:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.salesTax} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Dealer documentation fee:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={0} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Registration and license fee:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.registrationFee} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Total amount financed:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.amountFinanced} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Dealer participation fee:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.participationFee} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Acquisition fee:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency value={data.acquisitionFee} />
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text align="right" fontWeight="bold">
            Net proceeds to Dealer:
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Text>
            <Currency
              value={
                Number(data.dealerSalesPrice) +
                Number(data.participationFee) -
                Number(data.acquisitionFee)
              }
            />
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <Text>
            Accept all terms, submit to consumer for e-signing. Dealer hereby
            acknowledges adherence to all state laws and disclosure regarding
            selling under retail installment sales guidelines and agrees to
            fully assign executed contract to DriverUp upon submission below:
          </Text>
        </GridItem>
        <GridItem colSpan={2} centerContent={true}>
          {data.status === 'DEALER_APPROVAL_PENDING' && (
            <Center>
              <Button
                isLoading={mutation.isLoading && activeAction === 'a'}
                colorScheme={'blue'}
                onClick={onAccept}
                px="20"
              >
                Accept
              </Button>
            </Center>
          )}
        </GridItem>
        <GridItem colSpan={2}>
          {data.status === 'DEALER_APPROVAL_PENDING' && (
            <Center>
              <Button
                isLoading={mutation.iLoading && activeAction === 'r'}
                colorScheme={'red'}
                onClick={onReject}
                px="20"
              >
                Reject
              </Button>
            </Center>
          )}
        </GridItem>{' '}
        <GridItem colSpan={2}>
          {data.status === 'DEALER_APPROVAL_PENDING' && (
            <Center>
              <Button
                isLoading={mutation.iLoading && activeAction === 'u'}
                colorScheme={'red'}
                onClick={onUnavailable}
                px="20"
              >
                Vehicle Unavailable
              </Button>
            </Center>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
};

export default ApplicationDetail;

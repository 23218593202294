// Attempt to debug ENV VAR in Amplify console.
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import App from './app/app';
import { Provider } from 'react-redux';
import { ScrollToTop, ErrorBoundary } from '@driverup/ui';

import { store } from '@driverup/buyer/state';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();

const theme = extendTheme({
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <ErrorBoundary>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </ErrorBoundary>
        </ChakraProvider>
      </Provider>
    </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
);

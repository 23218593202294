import algoliasearch from 'algoliasearch/lite';
import { hits } from '@driverup/constants';
import { isDev } from '@driverup/util';
import { shouldUseLocalMockData } from '../util';
export const searchClient = algoliasearch(
  'Y7JU0GREO8',
  '78cc018b61961248423683117b831b3c'
);
export const searchClientMOCK = {
  search(requests) {
    // if (requests.every(({ params }) => !params.query)) {
    //   // No default search when nothing is typed in
    //   return null;
    // }

    return Promise.resolve({
      results: requests.map(() => ({
        // hits: require('./fixtures/algolia-response')?.hits?.slice(0, 4),
        // hits: require('@driverup/constants')?.hits?.slice(0, 4),
        hits: hits?.slice(0, 4),
        nbPages: 1,
        hitsPerPage: 20,
        facets: {
          make: { Mazda: 2, Nissan: 2 },
          year: { '2019': 2, '2015': 1, '2018': 1 },
          model: { 'LX Turbo': 1, MAZDA6: 1, Maxima: 1, Murano: 1 },
        },
        facets_stats: {
          year: { min: 2015, max: 2019, avg: 2017, sum: 8071 },
        },
      })),
    });
  },
};

let appSearchClient: any = searchClient;
// const algoliaIndex = appSearchClient.initIndex('cars_dev');
appSearchClient = shouldUseLocalMockData() ? searchClientMOCK : searchClient;
// export { appSearchClient, algoliaIndex };
export { appSearchClient };

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { formatMoney, getCarFromLocalStorage } from '@driverup/util';
import { Car, ROUTES } from '@driverup/constants';
import { util } from '@du/business-logic';
import NumberFormat from 'react-number-format';
import {
  CarCardList,
  useAuth,
  useDynamoPagination,
  DynamoPaginator,
} from '@driverup/ui';
import {
  getLoanAppsForBuyer,
  buyerDecision,
  deliveryDecision,
} from '@du/services';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  Badge,
  Box,
  Center,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  Wrap,
  Stack,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Table, Tbody, Td, Tr } from '@chakra-ui/table';
import Icon from '@chakra-ui/icon';
import { Button } from '@chakra-ui/button';
import { FaPencilAlt } from 'react-icons/fa';
import {
  FiMapPin as PinIcon,
  FiMail as MailIcon,
  FiPhone as PhoneIcon,
} from 'react-icons/fi';

/* eslint-disable-next-line */
export interface TransactionsProps {}

function formatLoanAppResponse(
  loanAppResponse: util.GetLoanAppsForBuyerResponse
) {
  return {
    id: loanAppResponse.id,
    carId: loanAppResponse.carId,
    dealerId: loanAppResponse.dealerId,
    dealer: loanAppResponse.dealer,
    contractUrl: loanAppResponse?.contractUrl,
    defiApplicationDecision: loanAppResponse.defiApplicationDecision,
    status: loanAppResponse?.status,
    approvalDetails: [
      {
        label: 'Amount Financed',
        // TODO: Perform all loan money formatting on Server. Client has to see exact terms
        value: formatMoney(loanAppResponse?.amountFinanced),
      },
      {
        label: 'Rate',
        value: loanAppResponse?.contractRate
          ? `${loanAppResponse?.contractRate}%`
          : null, // TODO: Get from?
      },
      {
        label: 'Term',
        value: `${loanAppResponse?.term} months`,
      },
      {
        label: 'Payment',
        value: loanAppResponse?.payment
          ? formatMoney(loanAppResponse?.payment)
          : null,
      },
    ],
  };
}

function LoanCard({ label, value }) {
  return (
    <Table variant="simple">
      <Tbody>
        {value && (
          <Tr key={label}>
            <Td>{label}</Td>
            <Td isNumeric>{value}</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
}

function LoanCardList({ data }) {
  return data.map((info) => {
    return <LoanCard key={info.label} {...info} />;
  });
}

export const Transactions = (props: TransactionsProps): React.ReactElement => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const username = user?.username;
  const {
    nextToken,
    nextNextToken,
    previousTokens,
    fetchNextPage,
    fetchPreviousPage,
    handleQuerySuccess,
  } = useDynamoPagination();
  const [activeLoan, setActiveLoan] = useState<
    { id: string; action: 'a' | 'r' | 'x' } | undefined
  >();
  const queryPayload = {
    buyerId: username,
    limit: 6,
    nextToken,
  };

  useEffect(() => {
    queryClient.invalidateQueries('loan_applications_for_buyer');
  }, [queryClient, nextToken]);

  const query = useQuery(
    ['loan_applications_for_buyer', nextToken],
    () => getLoanAppsForBuyer(queryPayload),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!username,
      onSettled: handleQuerySuccess,
    } // TODO: Set enabled to true
    // { retry: false, refetchOnWindowFocus: false, enabled: false } // TODO: Set enabled to true
  );
  const { isLoading, isError, data } = query;
  const buyerMutation = useMutation(buyerDecision, {
    onSettled: () => {
      // Perhaps this should only be onSuccess, for when the mutation succeeds.
      //    onSettled also runs on an error.  But, I'm not sure of specific
      //    error handling strategies for this yet.
      setActiveLoan(undefined);
      queryClient.invalidateQueries('loan_applications_for_buyer');
    },
  });
  const deliveryMutation = useMutation(deliveryDecision, {
    onSettled: () => {
      setActiveLoan(undefined);
      queryClient.invalidateQueries('loan_applications_for_buyer');
    },
  });
  const handleNavigation = (route) => {
    history.push(route);
  };
  const handleApproval = (id: string) => {
    const input = {
      input: {
        shouldApproveLoan: true,
        loanAppId: id,
      },
    };
    setActiveLoan({ id, action: 'a' });
    buyerMutation.mutate(input);
  };
  const handleReject = (id: string) => {
    const input = {
      input: {
        shouldApproveLoan: false,
        loanAppId: id,
      },
    };
    setActiveLoan({ id, action: 'r' });
    buyerMutation.mutate(input);
  };
  const handleDeliveryChoice = (id: string, choice: 'a' | 'r' | 'x') => {
    // we're resuing the 'a' and 'r' choices from the approve/reject
    const choices = {
      a: 'PICKUP',
      r: 'DELIVERY',
      x: 'RESET',
    };
    const input = {
      input: {
        deliveryDecision: choices[choice],
        loanAppId: id,
      },
    };
    setActiveLoan({ id, action: choice });
    deliveryMutation.mutate(input);
  };
  const loanApps: util.GetLoanAppsForBuyerResponse[] = data?.items;
  const formattedLoanApps = loanApps?.map(formatLoanAppResponse);

  if (isLoading) {
    return (
      <Center h="50vh">
        <VStack>
          <Spinner size="xl" color="blue.500" />
          <Text>Please wait...</Text>
        </VStack>
      </Center>
    );
  }

  if (isError) {
    return (
      <div className="mt-24 text-center text-red-700">
        Uh oh, something went wrong while loading your transactions.
      </div>
    );
  }

  return (
    <Box>
      {formattedLoanApps?.length ? (
        <Box p={[2, 6, 12]}>
          <Heading as="h2" size="lg">
            Loan Applications
          </Heading>

          <SimpleGrid columns={[1, 2]} spacing={8} mt={6}>
            {formattedLoanApps.map((data) => {
              const { approvalDetails } = data;
              return (
                <Box key={data.id} maxW={'420px'} w={'full'}>
                  <Badge
                    title={data.id}
                    cursor={'default'}
                    colorScheme={
                      data.status === 'REJECTED_BY_DEALER' ? 'red' : 'blue'
                    }
                  >
                    {data.status}
                  </Badge>
                  <LoanCardList data={approvalDetails} />
                  {data.status === 'VEHICLE_UNAVAILABLE' ? (
                    <>
                      <Text mt={8} mx={1}>
                        This was a great choice but unfortunately this vehicle
                        is longer available. There are many more good ones to
                        pick from so click below to continue shopping on
                        DriverUp!
                      </Text>
                      <Button
                        as="a"
                        mt="5"
                        onClick={() => handleNavigation(ROUTES.CARS)}
                        isFullWidth
                        colorScheme={'blue'}
                      >
                        Continue shopping
                      </Button>
                    </>
                  ) : null}
                  {data.status === 'BUYER_APPROVAL_PENDING' ? (
                    <>
                      <Button
                        as="a"
                        mt="5"
                        onClick={() => handleApproval(data.id)}
                        isFullWidth
                        colorScheme={'blue'}
                        isLoading={
                          buyerMutation.isLoading &&
                          activeLoan?.id === data.id &&
                          activeLoan?.action === 'a'
                        }
                      >
                        Continue with purchase
                      </Button>
                      <Button
                        as="a"
                        mt="5"
                        onClick={() => handleReject(data.id)}
                        isFullWidth
                        colorScheme={'red'}
                        isLoading={
                          buyerMutation.isLoading &&
                          activeLoan?.id === data.id &&
                          activeLoan?.action === 'r'
                        }
                      >
                        Cancel purchase
                      </Button>
                    </>
                  ) : null}
                  {data.status === 'BUYER_SIGNATURE_PENDING' ? (
                    <Button
                      as="a"
                      mt="5"
                      href={data.contractUrl}
                      isFullWidth
                      leftIcon={<Icon as={FaPencilAlt} />}
                      colorScheme={'blue'}
                    >
                      Sign Contract
                    </Button>
                  ) : null}
                  {data.status === 'SIGNATURES_COMPLETE' ? (
                    <>
                      <Text mt={8} mx={1}>
                        Congratulations on your purchase. You've made a great
                        decision and we hope the DriverUp experience was a good
                        one. All that is left is to simply select below how you
                        want to get your vehicle. Click on one of the following
                      </Text>
                      <Button
                        mx={1}
                        as="a"
                        mt={5}
                        colorScheme={'blue'}
                        isFullWidth
                        onClick={() => handleDeliveryChoice(data.id, 'a')}
                        isLoading={
                          deliveryMutation.isLoading &&
                          activeLoan?.id === data.id &&
                          activeLoan?.action === 'a'
                        }
                      >
                        I will come pick up
                      </Button>
                      <Button
                        mx={1}
                        as="a"
                        mt={5}
                        colorScheme={'blue'}
                        isFullWidth
                        onClick={() => handleDeliveryChoice(data.id, 'r')}
                        isLoading={
                          deliveryMutation.isLoading &&
                          activeLoan?.id === data.id &&
                          activeLoan?.action === 'r'
                        }
                      >
                        Please deliver to me
                      </Button>
                    </>
                  ) : null}
                  {data.status === 'SCHEDULING_PICKUP' ? (
                    <>
                      <Center>
                        <List spacing={2} mt={5}>
                          <ListItem>
                            <Text>
                              <b>{data.dealer.name}</b>
                            </Text>
                          </ListItem>
                          <ListItem>
                            <Stack direction={'row'}>
                              <ListIcon as={PinIcon} mt={1} />
                              <Box>
                                {data.dealer.address.addressLine1}
                                <br />
                                {data.dealer.address.city},{' '}
                                {data.dealer.address.state}
                                <br />
                                {data.dealer.address.zip}
                              </Box>
                            </Stack>
                          </ListItem>
                          <ListItem>
                            <Stack direction={'row'}>
                              <ListIcon as={PhoneIcon} mt={1} />
                              <Box>
                                <NumberFormat
                                  value={data.dealer.phoneNumber}
                                  displayType={'text'}
                                  format="(###) ###-####"
                                />
                              </Box>
                            </Stack>
                          </ListItem>
                          <ListItem>
                            <Stack direction={'row'}>
                              <ListIcon as={MailIcon} mt={1} />
                              <Box>{data.dealer.email}</Box>
                            </Stack>
                          </ListItem>
                        </List>
                      </Center>

                      <Text mt={8} mx={1}>
                        {data.dealer.name} will be contacting you shortly to
                        schedule an appointment to personally hand you your keys
                        and get you on your way promptly. We can't wait to have
                        you driving soon.
                      </Text>
                      <Text mt={8} mx={1}>
                        Thank you for using DriverUp.
                      </Text>
                      <Center>
                        <Button
                          variant="link"
                          mx={1}
                          as="a"
                          mt="5"
                          colorScheme={'blue'}
                          onClick={() => handleDeliveryChoice(data.id, 'x')}
                          isLoading={
                            deliveryMutation.isLoading &&
                            activeLoan?.id === data.id &&
                            activeLoan?.action === 'x'
                          }
                        >
                          Change how I get my vehicle
                        </Button>
                      </Center>
                    </>
                  ) : null}
                  {data.status === 'WAITING_FOR_DELIVERY' ? (
                    <>
                      <Text mt={8} mx={1}>
                        We will be contacting you shortly to arrange a
                        convenient time to personal deliver your vehicle to you.
                        Sanatized, detailed and ready for you to hit the road.
                        We can't wait to have you driving soon.
                      </Text>
                      <Text mt={8} mx={1}>
                        Thank you for using DriverUp.
                      </Text>
                      <Center>
                        <Button
                          variant="link"
                          mx={1}
                          as="a"
                          mt="5"
                          colorScheme={'blue'}
                          onClick={() => handleDeliveryChoice(data.id, 'x')}
                          isLoading={
                            deliveryMutation.isLoading &&
                            activeLoan?.id === data.id &&
                            activeLoan?.action === 'x'
                          }
                        >
                          Change how I get my vehicle
                        </Button>
                      </Center>
                    </>
                  ) : null}
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
      ) : (
        <div className="mt-24 text-center">You have no loan applications.</div>
      )}
      {/* {car ? (
        <div>
          <h2 className="text-xl font-bold text-gray-900">{`${car.status} Orders`}</h2>
          <CarCardList cars={[car]} variant="Order" />
        </div>
      ) : (
        <div className="mt-24 text-center">You have no transactions.</div>
      )} */}
      <DynamoPaginator
        nextNextToken={nextNextToken}
        previousTokens={previousTokens}
        fetchNextPage={fetchNextPage}
        fetchPreviousPage={fetchPreviousPage}
      />
    </Box>
  );
};

export default Transactions;
